import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { paySaleReceivables } from "../../../../services/SaleService";
import { toast } from "react-toastify";
import currencyBRFormat from "../../../../util/CurrencyUtils";

const FormaPagamentoVendaAPrazoModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const inputPaymentMethod = useRef("");

  function close() {
    props.setPaymentMethodModalOpen(false);
  }

  function paySales(e) {
    e.preventDefault();
    const execute = window.confirm(
      `Você tem certeza que deseja dar baixa ${
        props.selectedCustomer == null
          ? "nesta venda a prazo"
          : `nas vendas a prazo do cliente ${props.selectedCustomer.label}`
      } ?`
    );

    if (execute) {
      setLoading(true);
      const data = {
        salesIds: props.saleIds
          ? [props.saleIds]
          : props.sales.map((s) => s.id),
        paymentMethod: inputPaymentMethod.current.value,
      };

      paySaleReceivables(data)
        .then((response) => {
          toast.success(response);
          props.listSales();
          close();
        })
        .catch((error) =>
          toast.error("Não foi possivel dar baixa nas vendas a prazo")
        )
        .finally(() => setLoading(false));
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={close} backdrop="static">
      <ModalHeader toggle={close}>Liquidar vendas a prazo</ModalHeader>

      <form onSubmit={paySales}>
        <ModalBody>
          <div className="mb-2">
            <label>Cliente: {props.sales[0].customer.name}</label>
          </div>

          <div className="mb-2">
            <label>
              Total:{" "}
              {currencyBRFormat(
                props.sales
                  .map((s) => s.total)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
              )}
            </label>
          </div>
          <div>
            <label className="mb-1">Forma de pagamento</label>
            <select
              id="paymentMethod"
              ref={inputPaymentMethod}
              className="form-select"
              aria-label="Default select example"
              required
            >
              <option value="MONEY">Dinheiro</option>
              <option value="CREDIT">Crédito à vista</option>
              <option value="INSTALLMENT_CREDIT">Crédito parcelado</option>
              <option value="DEBIT">Débito</option>
              <option value="PIX">Pix</option>
            </select>
          </div>
        </ModalBody>

        <ModalFooter>
          {!loading ? (
            <div className="d-flex">
              <button className="btn btn-dark mx-3">Concluir</button>

              <button type="button" onClick={close} className="btn btn-danger">
                Cancelar
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default FormaPagamentoVendaAPrazoModal;
