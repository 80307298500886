import React, { useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import "./Dashboard.css";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { useEffect } from "react";
import { getDashboardInfo } from "../../../services/DashboardService";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Chart from "../../../components/Chart";
import { CDBSpinner } from "cdbreact";

export const Dashboard = () => {
  const [dashboardInfo, setDashboardInfo] = useState();
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    getDashboardInfo()
      .then((response) => {
        setDashboardInfo(response);
      })
      .catch((error) => console.error("[getDashboardInfo] ", error));
  }, []);

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          overflowY: "hidden",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />

        {!dashboardInfo ? (
          <div className="d-flex justify-content-center p-5">
            <CDBSpinner dark />
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <div
              style={{
                padding: "20px 5%",
              }}
            >
              <div className="row">
                <div className="col-lg-6 p-3">
                  <div className="card-bg border p-4 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="m-0 h5 bold text-dark">Hoje</h4>
                      <div className="py-1 px-2 bg-grey rounded-circle">
                        <i className="fa fa-dollar-sign"></i>
                      </div>
                    </div>
                    <h4 className="values my-4 text-right text-dark fw-bold">
                      {currencyBRFormat(
                        dashboardInfo ? dashboardInfo.grossRevenue : 0.0
                      )}
                    </h4>

                    <Link className="text-decoration-none" to="/relatorios">
                      <p className="c-p mb-0 text-dark text-right mt-auto">
                        Mais detalhes
                        <i className="fas fa-arrow-right mx-2"></i>
                      </p>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-6 p-3">
                  <div className="card-bg border p-4 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="m-0 h5 font-weight-bold text-dark">
                        Despesas a pagar
                      </h4>
                      <div className="py-1 px-2 bg-grey rounded-circle">
                        <i className="fa fa-envelope-open-text"></i>
                      </div>
                    </div>
                    <h4 className="values my-4 text-right text-dark fw-bold">
                      {dashboardInfo && dashboardInfo.exspensesNotPaid}
                    </h4>

                    <Link className="text-decoration-none" to="/despesas">
                      <p className="c-p mb-0 text-dark text-right mt-auto">
                        Mais detalhes
                        <i className="fas fa-arrow-right mx-2"></i>
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 p-3">
                  <div className="card-bg border p-4 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="m-0 h5 font-weight-bold text-dark">
                        Vendas a prazo
                      </h4>
                      <div className="py-1 px-2 bg-grey rounded-circle">
                        <i className="fa fa-info"></i>
                      </div>
                    </div>
                    <h4 className="values my-4 text-right text-dark fw-bold">
                      {dashboardInfo && currencyBRFormat(dashboardInfo.totalReceivables)}
                    </h4>

                    <Link className="text-decoration-none" to="/caixa?status=A prazo">
                      <p className="c-p mb-0 text-dark text-right mt-auto">
                        Mais detalhes
                        <i className="fas fa-arrow-right mx-2"></i>
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 p-3">
                  <div className="card-bg border p-4 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="m-0 h5 font-weight-bold text-dark">
                        Veículos atendidos hoje
                      </h4>
                      <div className="py-1 px-2 bg-grey rounded-circle">
                        <i className="fa fa-car"></i>
                      </div>
                    </div>
                    <h4 className="values my-4 text-right text-dark fw-bold">
                      {dashboardInfo && dashboardInfo.totalVehiclesToday}
                    </h4>
                  </div>
                </div>
                <div className="col-lg-6 p-3">
                  <div className="card-bg border p-4 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="m-0 h5 font-weight-bold text-dark">
                        Veículos atendidos neste mês
                      </h4>
                      <div className="py-1 px-2 bg-grey rounded-circle">
                        <i className="fa fa-car"></i>
                      </div>
                    </div>
                    <h4 className="values my-4 text-right text-dark fw-bold">
                      {dashboardInfo && dashboardInfo.totalVehiclesMonth}
                    </h4>
                  </div>
                </div>
                <div className="col-lg-12 p-3">
                  <div className="card-bg border">
                    <h4 className="p-3 m-0 h5 font-weight-bold text-dark">
                      Faturamento dias da semana
                    </h4>
                    <div className="p-3">
                      <Chart
                        sales={dashboardInfo && dashboardInfo.salesByDay}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-3">
                  <div className="card-bg border flex-column p-4">
                    <div className="d-flex">
                      <h6 className="h5 font-weight-bold text-dark">
                        Serviços mais solicitados
                      </h6>
                      <div className=" align-self-center  rounded-circle bg-grey py-1 px-2">
                        <i className="fas fa-star"></i>
                      </div>
                    </div>

                    <div className="d-flex flex-column mt-3">
                      {dashboardInfo &&
                        dashboardInfo.favoriteServices.map((service) => (
                          <div className="d-flex mt-3">
                            <div>
                              <h6
                                className="mb-0"
                                style={{ fontWeight: "600" }}
                              >
                                {service[0]}
                              </h6>
                              <p className="m-0" style={{ fontSize: "0.85em" }}>
                                {service[1]}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
