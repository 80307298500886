import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import PaginationBootstrap from "../../../../components/PaginationBootstrap";
import { useEffect, useState } from "react";
import Select from "react-select";
import { listAllSalesByStatus } from "../../../../services/SaleService";
import {
  downloadSalesReceivablesPDF,
  sendToWhatsAppSalesReceivablesPDF,
} from "../../../../services/ReportService";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import FormaPagamentoVendaAPrazoModal from "../modal/FormaPagamentoVendaAPrazoModal";
const dayjs = require("dayjs");

const VendasReceberTable = (props) => {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [sales, setSales] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [baixaButtonDisabled, setBaixaButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);

  useEffect(() => {
    if (props.sales) {
      setSales([...props.sales]);
    }
  }, [props.sales]);

  useEffect(() => {
    if (props.pageInfo) {
      setPageInfo(props.pageInfo);
    }
  }, [props.pageInfo]);

  function generateReport() {
    setLoading(true);
    const data = {
      customerName: selectedCustomer.label,
      sales,
    };

    downloadSalesReceivablesPDF(data)
      .then((response) => {
        var blob = new Blob([response], { type: "application/pdf" });
        FileSaver.saveAs(
          blob,
          `Relatório serviços realizados para ${selectedCustomer.label}.pdf`
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao baixar o arquivo:", error.message);
      });
  }

  function sendToWhatsApp() {
    setLoading(true);
    const data = {
      customerName: selectedCustomer.label,
      sales,
    };

    sendToWhatsAppSalesReceivablesPDF(data)
      .then((response) => {
        toast.success(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.success("Não foi possivel enviar relatório para o WhatsApp");
        setLoading(false);
        console.error("Erro ao enviar o relatorio:", error.message);
      });
  }

  function listSales(customerId) {
    listAllSalesByStatus("RECEIVABLES", customerId)
      .then((response) => {
        setSales(response.data);
        setPageInfo(response.pageInfo);
        if (response.data.length > 0) setBaixaButtonDisabled(false);
        else setBaixaButtonDisabled(true);
      })
      .catch((error) => {
        console.error(error);
        setBaixaButtonDisabled(true);
      });
  }

  function handleChangeCustomer(customer) {
    if (customer) {
      setSelectedCustomer(customer);
      listSales(customer.value);
    } else {
      setSelectedCustomer();
      setSales([...props.sales]);
      setPageInfo(props.pageInfo);
    }
  }

  return (
    <>
      {paymentMethodModalOpen && (
        <FormaPagamentoVendaAPrazoModal
          listSales={listSales}
          sales={sales}
          selectedCustomer={selectedCustomer}
          setPaymentMethodModalOpen={setPaymentMethodModalOpen}
        />
      )}

      <div className="d-lg-flex">
        <div style={{ minWidth: "18rem" }}>
          <Select
            id="customerSelect"
            name="customers"
            classNamePrefix="select"
            options={
              sales &&
              props.customers.filter((c) =>
                sales.some((s) => s.customer.name === c.label)
              )
            }
            className="basic-multi-select"
            value={selectedCustomer}
            onChange={(customer) => handleChangeCustomer(customer)}
            isClearable
            formatOptionLabel={formatCustomersLabels}
            placeholder="Filtrar vendas a prazo de um cliente"
          />
        </div>

        <div className="mx-lg-3 mx-0 mt-lg-0 mt-3 align-self-center">
          {!loading ? (
            <div className={baixaButtonDisabled ? "d-none" : ""}>
              <button
                className="btn btn-dark btn-sm"
                title="Baixar relatório de vendas a prazo deste cliente"
                onClick={generateReport}
              >
                {" "}
                <i className="fa fa-download me-2" aria-hidden="true"></i>
                Relatório
              </button>

              <button
                className="btn btn-dark btn-sm mx-3"
                title="Enviar relatório de vendas a prazo deste cliente para o WhatsApp"
                onClick={() => sendToWhatsApp()}
              >
                {" "}
                <i
                  className="fab fa-whatsapp fa-lg me-2"
                  aria-hidden="true"
                ></i>
                Enviar
              </button>

              <button
                className="btn btn-dark btn-sm mt-lg-0 mt-2"
                title="Dar baixa em todas vendas a prazo deste cliente"
                onClick={() => setPaymentMethodModalOpen(true)}
              >
                {" "}
                <i className="fa fa-check me-2" aria-hidden="true"></i>
                Liquidar todas
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-3">
        <CDBTable responsiveSm responsiveMd>
          <CDBTableHeader>
            <tr>
              <th>Data</th>
              <th>Veículo</th>
              <th>Cliente</th>
              <th>Forma Pagamento</th>
              <th>Total</th>
              <th>Ações</th>
            </tr>
          </CDBTableHeader>
          <CDBTableBody>
            {sales &&
              sales.map((sale) => (
                <>
                  <tr key={sale.id}>
                    <td>{dayjs(sale.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                    <td>{sale.vehicleInfo}</td>
                    <td>
                      {sale.customer ? sale.customer.name : "Não identificado"}
                    </td>
                    <td>{sale.paymentMethod}</td>
                    <td className="bold">
                      {currencyBRFormat(
                        parseFloat(sale.total) +
                          sale.adtionalValue -
                          sale.discount
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        //onClick={() => setPaymentMethodModalOpen(sale.id)}
                        onClick={() => setPaymentMethodModalOpen(true)}
                        className="btn btn-dark"
                        title="Dar baixa nessa venda"
                      >
                        Liquidar
                      </button>
                    </td>
                  </tr>
                </>
              ))}
          </CDBTableBody>
        </CDBTable>
        {sales && sales.length == 0 ? (
          <>
            Nenhuma venda a prazo{" "}
            {selectedCustomer ? "de " + selectedCustomer.label : ""}
          </>
        ) : (
          <div>
            <div className="d-flex justify-content-end">
              <label>Total a Receber</label>
              <div className="mx-2 ">
                {sales &&
                  currencyBRFormat(
                    sales
                      .map(
                        (sale) =>
                          sale.total + sale.adtionalValue - sale.discount
                      )
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                  )}
              </div>
            </div>
            {pageInfo && (
              <PaginationBootstrap
                pageInfo={pageInfo}
                goToPage={props.goToPage}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VendasReceberTable;

const formatCustomersLabels = (customer) => (
  <>
    <span>{customer.label}</span>
    <span> - {customer.key}</span>
  </>
);
