export default function paymentMethodBR(value) {
  switch (value) {
    case "MONEY":
      return "Dinheiro";
    case "CREDIT":
      return "Crédito à vista";
    case "INSTALLMENT_CREDIT":
      return "Crédito parcelado";
    case "DEBIT":
      return "Débito";
    case "PIX":
      return "Pix";
    case "BANK_SLIP":
      return "A prazo";
    default:
      return "Forma de pagamento não informada";
  }
}
